
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IMyHomepageDetail } from '@/types';
import IntroduceItem from '@/components/homepage/introduce-item.vue';
import IntroduceCompany from '@/components/homepage/introduce.vue';
import { UserModule } from '@/store/user';

@Component({
  name: 'HomepageIntroduce',
  components: {
    IntroduceCompany,
    IntroduceItem,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail

  get isManager() {
    return UserModule.userId === this.detail.userDetail.userId;
  }
}
