import { IMyHompageIntroduceSave } from '@/types';
import request from '@/utils/request';

const PATH = '/my-homepage/introduce';

export const getIntroduceList = (nickname: string) => request({
  url: `${PATH}/${nickname}`,
  method: 'get',
});

export const saveIntroduceList = (data: IMyHompageIntroduceSave[]) => request({
  url: PATH,
  method: 'post',
  data,
});
