
import { getIntroduceList } from '@/api/myHomepageIntroduce';
import { IMyHompageIntroduceDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageType1IntroduceItem',
})
export default class extends Vue {
  @Prop({ required: true }) private skinType!: number

  mounted() {
    this.init();
  }

  private introduceList: IMyHompageIntroduceDetail[] = [];

  private introduceIndex = -1;

  private heightList: number[] = [];

  private async init() {
    const list: any = await this.getList();
    list.forEach((item: any, index: number) => {
      this.heightList.push((this.$refs.answer as any)[index].scrollHeight);
      (this.$refs.answer as any)[index].className += ' fixed-height';
    });
  }

  private getList() {
    return new Promise((resolve) => {
      getIntroduceList(this.$route.params.nickname).then((res) => {
        this.introduceList = res.data;
        resolve(res.data);
      });
    });
  }

  private handleClickIntroduce(index: number) {
    this.introduceIndex = index;
  }
}
