
import { addInterestCompany, deleteInterestCompany, getMyInterestCompanyList } from '@/api/interestCompany';
import { UserModule } from '@/store/user';
import { IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'HomepageIntroduceCompany',
})
export default class extends Vue {
  @Prop({ required: true }) private skinType!: number;

  @Prop({ required: true }) detail!: IMyHomepageDetail;

  get isLogined() {
    return UserModule.token;
  }

  mounted() {
    getMyInterestCompanyList().then((res) => {
      this.interestCompanyList = res.data;
    });
  }

  private interestCompanyList: string[] = [];

  private getUrl(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) return url;
    return `http://${url}`;
  }

  private getGradeFile() {
    if (this.detail.userDetail.companyGradeFileUid) return `${process.env.VUE_APP_COMMON_API}/attached-file/${this.detail.userDetail.companyGradeFileUid}`;
    return '#';
  }

  private isInterestCompany(uid: string) {
    return this.interestCompanyList.indexOf(uid) > -1;
  }

  private handleClickInterest(uid: string, follow: boolean) {
    if (follow) {
      addInterestCompany(uid).then(() => {
        this.interestCompanyList.push(uid);
      });
    } else {
      deleteInterestCompany(uid).then(() => {
        const index = this.interestCompanyList.indexOf(uid);
        if (index > -1) this.interestCompanyList.splice(index, 1);
      });
    }
  }
}
